<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
  >
    <v-container>
      <div class="mt-0 mb-5">
        <h2>{{mode == 'new' ? '会社登録' : '会社編集'}}</h2>
      </div>

      <v-row>
        <v-col cols="12">
          <v-text-field
            label="登録番号"
            v-model.trim="companyData.company_id"
            maxlength="5"
            counter
            dense
            :readonly= "mode == 'new' ? false : true"
            :rules="[required_ns, validNumber, maxLength(5)]"
            :loading="loading"
            @change="companyData.company_id = format_companyid(companyData.company_id);"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="会社名"
            v-model.trim="companyData.company_name"
            maxlength="100"
            counter
            dense
            :readonly="mode=='delete' ? true : false"
            :rules="[required_ns]"
            :loading="loading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="会社名カナ"
            v-model.trim="companyData.company_kana"
            maxlength="100"
            dense
            counter
            :readonly="mode=='delete' ? true : false"
            :loading="loading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <NumberTextField
            label="資本金"
            v-model="companyData.capital"
            :readonly="mode=='delete' ? true : false"
            suffix="千円"
            :loading="loading"
            :rules="[maxValLocaleJP(999999999), minValLocaleJP(0)]"
          ></NumberTextField>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="col-6 text-center">
          <v-btn class="btn_font primary" width="120" v-on:click="registData()" :disabled="loading" v-if="mode != 'delete'">登録</v-btn>
          <v-btn class="btn_font secondary" width="120" v-on:click="deleteData()" :disabled="loading" v-else>削除</v-btn>
        </v-col>
        <v-col class="col-6 text-center">
          <v-btn class="btn_font primary" width="120" v-on:click="close()" :disabled="loading" >キャンセル</v-btn>
        </v-col>
      </v-row>

    </v-container>
  </v-form>

</template>

<script>
import NumberTextField from './common/Number_text_field'
import Mcompany from '../model/m_company'

import Message from '../Lib/Message'
import Debug from '../Lib/Debug'

export default {
  props: [
    'mode',
    'companyId',
  ],

  components: {
    NumberTextField,
  },

  data: () => ({
    valid : true,
    loading : false,

    companyData: {
      company_id: "",
      company_name: "",
      company_kana: "",
      capital: null,
    },

  }),

  methods: {
    // DBから 'userid' のデータを取得
    async getData(id) {
      Debug.log('function[getData]');
      Debug.log('edit companyId:' + id);
      if (id == '') {
        // 新規の場合
        // const res = await Mcompany.getNewId();
        // this.companyData.company_id = ('0000' + res.data).slice( -4 );

        // Debug.log2("Company_id->", this.companyData.company_id);

        return;
      }

      this.loading = true;
      try {
        const res = await Mcompany.getData(id);
        if (res.data) {
          this.companyData = res.data;
        }

        Debug.log(this.companyData);

      } catch (error) {
        Message.err(error, '情報を取得できませんでした');
      }
      this.loading = false;

    },

    async registData() {
      Debug.log('function[registData]');

      // Vuetify Validation
      if (!this.$refs.form.validate()) {
        //Debug.log('Validation : false');
        return;
      }


      if (!confirm("更新します。よろしいですか？")) return;
      this.loading = true;
      try {
        const res = await Mcompany.setCompanyData(this.companyData, this.mode);
        // Debug.log(res);

        // サーバーサイドのバリデーション
        const validationMsg = res.data;
        if (validationMsg) {
          let message = "";
          if (Array.isArray(validationMsg)) {
            validationMsg.forEach(m => message += m + '\n')
          } else {
            message = validationMsg;
          }
          alert(message);

          this.loading = false;
          return;
        } else {
          //
          alert("会社データを更新しました");
          this.close();
        }

      } catch (error) {
        Message.err(error, "会社データを更新できませんでした");
      }

      this.loading = false;
    },

    async deleteData() {
      Debug.log('function[deleteData]');

      if (!confirm("削除します。よろしいですか？")) return;
      this.loading = true;
      try {
        const res = await Mcompany.delCompanyData(this.companyId);
        Debug.log(res);

        alert("会社データを削除しました");
        this.close();
      } catch (error) {
        Message.err(error, "会社データを削除できませんでした");
      }

      this.loading = false;
    },

    close() {
      this.$emit("closeEvt");
    },

    // 新規会社の登録番号処理
    format_companyid(str) {
        if (str == null || str.length == 0) {
            return '';
        }

        const re = /^[0-9]+$/;
        if (re.test(str)) {
            // SPACE を削除
            let result = str.replaceAll(' ', '');

            // 4桁以下は 4桁とする
            if (str.length <= 4) {
                // 0 埋め で 4桁
                result = ('0000' + result).slice( -4 );
            }

            return result
        }

        return str;
    }

  },

  created: function() {
    // Edit情報を取得
    Debug.log('mode:' + this.mode);
    Debug.log('companyId:' + this.companyId);

    this.getData(this.companyId);
  },

  mounted: function() {

  },

}

</script>


<style scoped>
.container {
  position: relative;
  width: 95%    !important;
  left: 0;
  margin: 10px;
}

.btn_font {
  color: white;
}


.v-text-field {
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.v-input {
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: 4px;
  margin-bottom: 4px;
}

</style>
